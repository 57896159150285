import React, { Component } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import styled from "styled-components"

class PageTemplate extends Component {
  render() {
    const currentPage = this.props.data.wordpressPage;
    const alt_text = currentPage.featured_media && currentPage.featured_media.alt_text ? currentPage.featured_media.alt_text : `Unknown page image`;

    return (
      
      <Layout
      title={currentPage.yoast_meta.yoast_wpseo_title}
      metaDescription={currentPage.yoast_meta.yoast_wpseo_metadesc}
      pageImageURL={currentPage.featured_media ? currentPage.featured_media.localFile.childImageSharp.fixed.src : ``}
      >
        <Heading dangerouslySetInnerHTML={{ __html: currentPage.title }} />

        {currentPage.featured_media !== null &&
          <ImageContainer>
            {/* <Img fluid={currentPage.featured_media.localFile.childImageSharp.fluid}  /> */}
            <img src={currentPage.featured_media.localFile.childImageSharp.fixed.src} alt={`${alt_text}`} />
          </ImageContainer>
        }

        <PageText dangerouslySetInnerHTML={{ __html: currentPage.content }}></PageText>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            fixed(height: 800) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`

const Heading = styled.h1 `
  margin: 0;
  margin-top: 50px;
`;

// const ImageContainer = styled.div `
//   margin: 50px 0;
// `;
const ImageContainer = styled.div `
  margin: 50px 0;
  text-align: center;
  max-width: 100%;
    img {
      width: auto;
      max-width: 100%;
      height: auto;
    }
`;

const PageText = styled.div`
    line-height: 2em;
`;